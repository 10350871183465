/* light theme (deafault) */

body{

  background-color: white;
  color: black;
  font-family: Arial, sans-serif;
}

/* Dark theme */

body.dark-theme{

  background-color: black;
  color: white;
  
}




.box-main
{
  background-color: beige;
  height: 100px;
  width : 400px;
}


.nav-search{
  width: 30%;
}
.nav-logo{
  width:40%;
}
.nav-icon{
  width:30%px;
  display: flex;
}
.nav-wish{
  width:50px;
}

.nav-login{
  width:50px;
}

.nav-bell{
  width:50px;
}
.navbar{
  background-color: bisque;
 
  padding: 0 100px;
  height: 75px;
  display:flex;
  /* justify-content: center; */
  /* align-items: center; */

  
}

.nav-link-container{
  width:100%;
  display:flex;
  /* justify-content: center; */
  align-items: center;
}

.nav-links{
  background-color: orange;
  display:flex;
  padding: 0 80px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
 


}

.nav-cart{
  
  display: flex;
}

Link{
  margin:0 10px;
}


.Main
{
  padding: 30px;
  /* background-color: #282c34; */
}
.Footer {
  height:100%;
  padding: 10px 0;
}

.Copy-write {
  width:50%;
  float:left;
  
}

.Social-media {
  width:50%;
  display: flex;
  justify-content: flex-end;
}

.Footer-link {
  margin:0 5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  margin:0 5px;
  
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
